import React, { Component } from 'react'
import { Link } from 'gatsby'

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer container">
       {/* CC BY-NC 4.0<a href="#">   </a> */}
        <a href="/sanggahan" target="_blank" rel="noopener noreferrer">
          Sanggahan
        </a> 
        <a href="/kebijakan" target="_blank" rel="noopener noreferrer">
        Kebijakan
        </a>
        <a href="/ketentuan" target="_blank" rel="noopener noreferrer">
        Ketentuan
        </a>
        <a href="/rss.xml" target="_blank" rel="noopener noreferrer">
          RSS
        </a>
      </footer>
      
    )
  }
}
